$(function(){
$('a[href^="#"]').click(function(){
      var adjust = -180;
      var speed = 400;
      var href= $(this).attr("href");
      var target = $(href == "#" || href == "" ? 'html' : href);
      if (window.matchMedia("(max-width: 768px)").matches) {
        var position = target.offset().top - 100;
      }else{
        var position = target.offset().top + adjust;
      }
      $('body,html').animate({scrollTop:position}, speed, 'swing');
      return false;
    });

    //アコーディオン
    $(".open-info__btn").click(function(){
      console.log("ok");
      $(this).toggleClass("active");
      $(".open__inner").toggleClass("active");
      $(".open-info__area").slideToggle();
    });
});

$(function(){
  const swiper = new Swiper(".wrap-wave02", {
    loop: true,
    lallowTouchMove: false,
    spaceBetween: -5,
    speed: 6000,
    slidesPerView: 1　/* この行を追加 */,
    autoplay: { // 自動再生
      delay: 0, // 秒後に次のスライド
    },
    breakpoints: {
      // スライドの表示枚数：px以上の場合
      991: {
        slidesPerView: 1,
        spaceBetween: -5,
      }
    }
  });

  const swiper2 = new Swiper(".wrap-wave03", {
    loop: true,
    lallowTouchMove: false,
    spaceBetween: -5,
    speed: 6000,
    slidesPerView: 1　/* この行を追加 */,
    autoplay: { // 自動再生
      delay: 0, // 秒後に次のスライド
    },
    breakpoints: {
      // スライドの表示枚数：px以上の場合
      991: {
        slidesPerView: 1,
        spaceBetween: -5,
      }
    }
  });
});

//フェード
$(function(){
  $(".fadeIn_main").on("inview", function (event, isInView) {
    if (isInView) {
      $(this).stop().addClass("is-show");
    }
  });
  $(".fadeIn_bg").on("inview", function (event, isInView) {
    if (isInView) {
      $(this).stop().addClass("is-show");
    }
  });
  $(".fadeIn_normal").on("inview", function (event, isInView) {
    if (isInView) {
      $(this).stop().addClass("is-show");
    }
  });
  $(".fadeIn_up").on("inview", function (event, isInView) {
    if (isInView) {
      $(this).stop().addClass("is-show");
    }
  });
  $(".fadeIn_left").on("inview", function (event, isInView) {
    if (isInView) {
      $(this).stop().addClass("is-show");
    }
  });
  $(".fadeIn_left2").on("inview", function (event, isInView) {
    if (isInView) {
      $(this).stop().addClass("is-show");
    }
  })
  $(".fadeIn_right").on("inview", function (event, isInView) {
    if (isInView) {
      $(this).stop().addClass("is-show");
    }
  });
  $(".fadeIn_right2").on("inview", function (event, isInView) {
    if (isInView) {
      $(this).stop().addClass("is-show");
    }
  });
});

function initPages() {
  decodeMailTo();
};

function decodeMailTo() {
  const mailto = document.querySelectorAll('[data-js-address]');

  mailto.forEach((element) => {
    const mail = element.getAttribute('data-js-address');
    const mailDecoded = atob(mail);
    element.setAttribute('href', `mailto:${mailDecoded}`);
    element.textContent = mailDecoded;
  });
};

window.addEventListener('load', function() {
  initPages();
})